import {
    put,
    select,
    all,
    takeEvery,
    delay,
    call,
} from '@redux-saga/core/effects';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import { createType } from './core';
import { objectHandler } from './object';

export const PREFIX = 'toasts';

export enum ToastType {
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
    Danger = 'danger',
}

export enum ToastRenderState {
    Created = 'created',
    Idle = 'idle',
    Closed = 'closed',
}

export interface IToast {
    id?: string;
    title: string;
    type?: ToastType;
    lifetime?: number;
}

export type ToastModel = IToast & {
    renderState?: ToastRenderState;
}

const DEFAULT_LIFETIME = 7000;
const DEFAULT_ERROR_LIFETIME = 15000;

interface ToastsState {
    items: ToastModel[];
}

export const toastsHandler = objectHandler<ToastsState>(PREFIX, 'collection', () => ({ items: [] }));

export const getToasts = createSelector<any, any>(toastsHandler.selector, (state: ToastsState) => state.items);

export const ADD_TOAST = createType(PREFIX, 'add');
export const CLOSE_TOAST = createType(PREFIX, 'close');

export const addToast = (toast: IToast) => ({
    type: ADD_TOAST,
    toast,
});
export const addToastInfo = (title: string) => addToast({
    title,
    type: ToastType.Info,
});
export const addToastSuccess = (title: string) => addToast({
    title,
    type: ToastType.Success,
});
export const addToastWarning = (title: string) => addToast({
    title,
    type: ToastType.Warning,
    lifetime: DEFAULT_ERROR_LIFETIME,
});
export const addToastError = (title: string) => addToast({
    title,
    type: ToastType.Danger,
    lifetime: DEFAULT_ERROR_LIFETIME,
});
export const closeToast = (id: string) => ({
    type: CLOSE_TOAST,
    id,
});

function* updateToastState(id: string, data: Partial<ToastModel>) {
    const toasts: ToastModel[] = yield select(getToasts);

    yield put(toastsHandler.update({
        items: toasts.map(toast => {
            if (toast.id === id) {
                return {
                    ...toast,
                    ...data,
                };
            }

            return toast;
        }),
    }));
}

function* addToastSaga({ toast }: { toast: IToast }) {
    let toasts: ToastModel[] = yield select(getToasts);

    // Events deduplication by uid or by title.
    if (toast.id) {
        toasts = toasts.filter(t => t.id !== toast.id);
    } else if (toast.title) {
        toasts = toasts.filter(t => t.title !== toast.title);
    }

    const toastId = toast.id || uuidv4();
    yield put(toastsHandler.update({
        items: [
            ...toasts,
            {
                ...toast,
                id: toastId,
                type: toast.type || ToastType.Success,
                renderState: ToastRenderState.Created,
            },
        ],
    }));

    yield delay(10);

    yield call(updateToastState, toastId, { renderState: ToastRenderState.Idle });

    yield delay(toast.lifetime || DEFAULT_LIFETIME);

    yield put(closeToast(toastId));
}

function* closeToastSaga({ id }: { id: string }) {
    yield call(updateToastState, id, { renderState: ToastRenderState.Closed });

    yield delay(500);

    const toasts: ToastModel[] = yield select(getToasts);
    yield put(toastsHandler.update({
        items: toasts.filter(toast => toast.id !== id),
    }));
}

export const reducer = combineReducers({
    ...toastsHandler.reducerInfo,
});

export function* rootSaga() {
    yield all([
        takeEvery<any>(ADD_TOAST, addToastSaga),
        takeEvery<any>(CLOSE_TOAST, closeToastSaga),
    ]);
}
