type IStore<TSource extends string|number = any> = {
    [source in TSource]: {
        timeoutId: NodeJS.Timeout;
        latestRejector: (reason?: any) => void;
    }
};

export interface DebounceOptions {
    throwErrorOnReject: boolean;
    timeout: number;
}

const DEFAULT_OPTIONS: DebounceOptions = {
    throwErrorOnReject: false,
    timeout: 100,
};

const DEFAULT_SOURCE = '__default';

export class DebounceRejectError extends Error {
    source?: string|number;

    constructor(message?: string, source?: string|number) {
        super(message);

        this.source = source;
        this.name = 'DebounceRejectError';
    }
}

export function withDebounce<TResult = any>(callback: (...args) => Promise<TResult>|TResult, options?: Partial<DebounceOptions>, sourceGetter?: (...args) => string|number): (...args) => Promise<TResult> {
    const store: IStore = {};

    const opt = {
        ...DEFAULT_OPTIONS,
        ...(options || {}),
    };

    return (...args) => {
        const task = new Promise<TResult>((resolve, reject) => {
            const source = sourceGetter?.(...args) || DEFAULT_SOURCE;

            if (opt.throwErrorOnReject && typeof store[source]?.latestRejector === 'function') {
                store[source].latestRejector(new DebounceRejectError('The current execution was reject by another call.'));
            }

            clearTimeout(store[source]?.timeoutId);
            delete store[source];

            const timeoutId = setTimeout(() => {
                delete store[source];

                try {
                    const result = callback(...args);

                    resolve(result);
                } catch (e) {
                    reject(e);
                }
            }, opt.timeout);

            store[source] = {
                timeoutId,
                latestRejector: reject,
            };
        });

        return task;
    };
}