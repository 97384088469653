import { combineReducers } from 'redux';
import {
    all,
    fork,
} from 'redux-saga/effects';
import objectMapValues from '../util';
import { IBaseHandler } from './props';

const BRAND = 'jl';

interface SelectorMap {
    [key: string]: (state: any) => any;
}

const DEFAULT_DUCK_MODULE_VERSION = 1;
const AVAILABLE_DUCK_MODULE_VERSIONS = [
    1,
    2,
];

export interface IDuckModule {
    PREFIX: string;
    VERSION?: number;

    reducer?: any;
    rootSaga?(): any;
    subscriptions?(): any;
}

export interface DucksCollectionObject {
    [name: string]: IDuckModule;
}

export interface DucksReducers {
    [prefix: string]: any;
}

/**
 * Create the string type name for REDUX actions.
 * @param group The group name
 * @param typeName The target type name
 * @return The global branded type name, like: `jl/auth/login`
 */
export function createType(group: string, typeName: string): string {
    return `${BRAND}/${group}/${typeName}`;
}

export function mapSelectorsToProps(selectorsByProp: SelectorMap): any {
    return state => objectMapValues(selectorsByProp, selector => selector(state));
}

export function getReducers(ducks: DucksCollectionObject): DucksReducers {
    return Object.keys(ducks)
        .map(duckName => {
            const {
                reducer,
                PREFIX,
                VERSION,
            } = ducks[duckName];
            return [
                reducer,
                PREFIX,
                VERSION ?? DEFAULT_DUCK_MODULE_VERSION,
                duckName,
            ];
        })
        .filter(([reducer, PREFIX, VERSION, duckName]) => {
            if (!AVAILABLE_DUCK_MODULE_VERSIONS.includes(VERSION)) {
                throw new Error(`duck "${duckName}" uses unrecognized version ${VERSION}, available versions are [${AVAILABLE_DUCK_MODULE_VERSIONS.join(', ')}]`);
            }

            if (VERSION === 1) {
                if (reducer && !PREFIX) {
                    throw new Error(`duck "${duckName}" with reducer requires a PREFIX`);
                }

                return reducer;
            }

            if (VERSION > 1) {
                if (!PREFIX) {
                    throw new Error(`duck "${duckName}" required to export a PREFIX constant`);
                }
            }

            return true;
        })
        .reduce((acc, [reducer, PREFIX, VERSION, duckName]) => {
            if (acc[PREFIX]) {
                throw new Error(`duck with "${PREFIX}" prefix can not be registered because this prefix already registered`);
            }

            if (VERSION > 1) {
                if (!reducer) {
                    // When reducer is not provided by module, generate it automatically for all handlers inside
                    const handlersWithReducerInfo: IBaseHandler[] = Object.values(ducks[duckName])
                        .filter(item => item && typeof item === 'object')
                        .filter((item: IBaseHandler) => typeof item.reducerInfo === 'object');

                    if (handlersWithReducerInfo.length > 0) {
                        reducer = combineReducers(
                            handlersWithReducerInfo.reduce((carry, handler) => ({
                                ...carry,
                                ...handler.reducerInfo,
                            }), {}) as any
                        );
                    }
                }
            }

            if (typeof reducer === 'function') {
                acc[PREFIX] = reducer;
            }

            return acc;
        }, {});
}

export function getSagas(ducks: DucksCollectionObject) {
    return Object.values(ducks)
        .map(duck => {
            const VERSION = duck.VERSION ?? DEFAULT_DUCK_MODULE_VERSION;

            if (VERSION > 1) {
                // When root saga is not provided by module, generate it automatically for all handlers inside
                if (!duck.rootSaga) {
                    const handlersWithEffects: IBaseHandler[] = Object.values(duck)
                        .filter(item => item && typeof item === 'object')
                        .filter((item: IBaseHandler) => typeof item.effects === 'object' && Array.isArray(item.effects));

                    if (handlersWithEffects.length > 0) {
                        const effects = handlersWithEffects.flatMap(handler => handler.effects);
                        return function* rootSaga() {
                            if (duck.subscriptions) {
                                yield fork(duck.subscriptions);
                            }

                            yield all(effects);
                        };
                    }

                    // Use subscriptions instead of rootSaga for version 1+ of duck module
                    return duck.subscriptions;
                }
            }

            return duck.rootSaga;
        })
        .filter(Boolean);
}
