import { Reducer } from 'react';

export interface BaseActionType {
    type: string;
}

export type UpdateActionType<S = any, K extends string = 'value'> = BaseActionType & {
    [P in K]: S;
};

type P = { name: string };

type T = UpdateActionType<P, 'value'>;
type InferP = T extends UpdateActionType<infer TP> ? TP : never;

export type ActionCreatorType<S = any, K extends string = 'value'> = (value?: Partial<S>) => UpdateActionType<S, K>;

export function createUpdateAction<S = any, K extends string = 'value'>(UPDATE: string, key = 'value'): ActionCreatorType<S, K> {
    return (value?: S) => ({
        type: UPDATE,
        [key]: value,
    }) as UpdateActionType<S, K>;
}

type InitialStateProvider<S> = () => S;

export function createUpdateReducer<S = any>(UPDATE: string, getInitialState: InitialStateProvider<S>): Reducer<S, any> {
    return (state: S = getInitialState(), action: { type: string; value: S }): S => {
        const {
            type,
            value,
        } = action;

        switch (type) {
        case UPDATE:
            if (typeof value === 'undefined') {
                return getInitialState();
            }

            return {
                ...state,
                ...value,
            };
        default:
            return state;
        }
    };
}

export function createObjectReducer<S = any>(UPDATE: string, getInitialState: InitialStateProvider<S> = () => ({} as any)): Reducer<S, any> {
    return (state = getInitialState(), action: { type: string; value: S }) => {
        const {
            type,
            value,
        } = action;

        switch (type) {
        case UPDATE:
            if (typeof value === 'undefined') {
                return getInitialState();
            }

            return {
                ...state,
                ...value,
            };
        default:
            return state;
        }
    };
}
